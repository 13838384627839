import React, { useEffect } from "react";
import Footer from "../Component/Footer";
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import * as Yup from 'yup';
import { useFormik } from "formik";
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const hiringSchema = Yup.object().shape({
        hiringName: Yup.string().required('* First name is required'),
        hiringLastname: Yup.string(),
        hiringMobile: Yup.string()
            .matches(/^\+91[0-9]{10}$/, '* Phone number must be valid')
            .required('* Phone number is required'),
        hiringEmail: Yup.string().email('* Invalid email format').required('* Email is required'),
        hiringMessage: Yup.string().required('* Message is required'),
    });

    const formik = useFormik({
        initialValues: {
            hiringName: '',
            hiringLastname: '',
            hiringMobile: '',
            hiringEmail: '',
            hiringMessage: ''
        },
        validationSchema: hiringSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            try {
                const formData = new FormData();
                formData.append('fname', values.hiringName);
                formData.append('lname', values.hiringLastname);
                formData.append('mobile', values.hiringMobile);
                formData.append('email', values.hiringEmail);
                formData.append('message', values.hiringMessage);

                if (values.id) {
                      await axios.patch(`https://plexus-technology.in/api/user/update/${values.id}`, formData);
                } else {
                     await axios.post('https://plexus-technology.in/api/user/create', formData, {
                        headers: { "Content-Type": "multipart/form-data" }
                    });
                }

                toast.success('Message send successfully!');
                setSubmitting(false);
                resetForm();
            } catch (error) {
                console.error('Form submission error:', error);
                toast.error("An error occurred. Please try again.");
                setSubmitting(false);
            }
        },
    });

    return (
        <div>
            <Container>
                <div className="py-5">
                    <h2>Let’s Start Talk</h2>
                    <p className="m-0">Reach out to us and let’s discuss how.</p>
                    <p>We’re here to listen and help you achieve your goals.</p>
                </div>

                <section className="pt-20 mx-auto hiring-form">
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="hiringName">First Name </Label>
                                    <Input
                                        id="hiringName"
                                        name="hiringName"
                                        type="text"
                                        placeholder="First Name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.hiringName}
                                        className="py-2"
                                    />
                                    {formik.touched.hiringName && formik.errors.hiringName && (
                                        <div className="text-danger error-message">{formik.errors.hiringName}</div>
                                    )}
                                </FormGroup>
                            </Col>

                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="hiringLastname">Last Name </Label>
                                    <Input
                                        id="hiringLastname"
                                        name="hiringLastname"
                                        type="text"
                                        placeholder="Last Name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.hiringLastname}
                                        className="py-2"
                                    />
                                    {formik.touched.hiringLastname && formik.errors.hiringLastname && (
                                        <div className="text-danger error-message">{formik.errors.hiringLastname}</div>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>

                        <FormGroup className="pt-3">
                            <Label for="hiringMobile">Phone number </Label>
                            <Input
                                id="hiringMobile"
                                name="hiringMobile"
                                type="text"
                                placeholder="+91 00000-00000"
                                onChange={e => {
                                    // Allow only numeric input
                                    const inputValue = e.target.value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                    if (!inputValue.startsWith('91')) {
                                        formik.setFieldValue('hiringMobile', '+91' + inputValue.slice(0, 10));
                                    } else {
                                        formik.setFieldValue('hiringMobile', '+91' + inputValue.slice(2, 12)); // Limit to 10 digits after +91
                                    }
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.hiringMobile}
                                className="py-2"
                                onInput={(e) => {
                                    const inputValue = e.target.value;
                                    // Show error if input contains non-numeric characters
                                    if (/\D/.test(inputValue)) {
                                        formik.setFieldError('mobile', 'Please enter only numeric values.');
                                    } else if (inputValue.length > 13) { // +91 + 10 digits = 13 characters
                                        formik.setFieldError('mobile', 'Phone number should only be 10 digits.');
                                    } else {
                                        formik.setFieldError('mobile', null);
                                    }
                                }}
                            />
                            {formik.touched.hiringMobile && formik.errors.hiringMobile && (
                                <div className="text-danger error-message">{formik.errors.hiringMobile}</div>
                            )}
                        </FormGroup>

                        <FormGroup className="pt-3">
                            <Label for="hiringEmail">Email </Label>
                            <Input
                                id="hiringEmail"
                                name="hiringEmail"
                                type="email"
                                placeholder="you@company.com"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.hiringEmail}
                                className="py-2"
                            />
                            {formik.touched.hiringEmail && formik.errors.hiringEmail && (
                                <div className="text-danger error-message">{formik.errors.hiringEmail}</div>
                            )}
                        </FormGroup>

                        <FormGroup className="pt-3">
                            <Label for="hiringMessage">Message </Label>
                            <Input
                                id="hiringMessage"
                                name="hiringMessage"
                                type="textarea" // specify type as 'textarea' for multiline input
                                placeholder="Leave us a message..."
                                rows={5}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.hiringMessage}
                                className="py-2"
                            />
                            {formik.touched.hiringMessage && formik.errors.hiringMessage && (
                                <div className="text-danger error-message">{formik.errors.hiringMessage}</div>
                            )}
                        </FormGroup>

                        <Button
                            type="submit"
                            block
                            disabled={formik.isSubmitting}
                            className="py-3 rounded-pill border-white"
                            style={{ backgroundColor: "#0777AB" }}
                        >
                            {formik.isSubmitting ? "Sending..." : "Send Message"}
                        </Button>
                    </Form>
                </section>
            </Container>
            <Footer />


            <ToastContainer />
        </div>
    );
};

export default Contact;
