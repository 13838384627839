import React, { useState, useEffect, lazy, Suspense } from 'react';
import Loading from '../Component/Loading';

// Lazy load components
const Header = lazy(() => import('../Component/Header'));
const HeroSection = lazy(() => import('../Component/Home/HeroSection'));
const Languages = lazy(() => import('../Component/Home/Language'));
const About = lazy(() => import('../Component/Home/about'));
const AboutUs = lazy(() => import('../Component/Home/About2'));
const Portfolio = lazy(() => import('../Component/Home/Portfolio'));
const Footer = lazy(() => import('../Component/Footer'));
const Footer2 = lazy(() => import('../Component/Home/footer2'));
const HiringSection = lazy(() => import('../Component/Home/HiringSection'));

const Home = () => {
    const [portfolioData, setPortfolioData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://plexus-technology.in/api/portfolio/read');
                if (!response.ok) {
                    throw new Error('Failed to fetch portfolio data');
                }
                const data = await response.json();
                setPortfolioData(data.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching portfolio data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <Suspense fallback={<Loading />}>
                    <Header />
                    <HeroSection />
                    <Languages />
                    <About />
                    <AboutUs />
                    <Portfolio data={portfolioData} />
                    <HiringSection />
                    <Footer2 />
                    <Footer />
                </Suspense>
            )}
        </>
    );
};

export default Home;
